import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import classNames from "classnames"

import Helmet from "react-helmet"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import { Navigation } from "."
import config from "../../utils/siteConfig"

// @material-ui/icons
import { Apps, CloudDownload, Description } from "@material-ui/icons"

import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "components/CustomButtons/Button.jsx"
import Parallax from "components/Parallax/Parallax.jsx"

// Styles
// import '../../styles/app.css'
import Header from "components/Header/Header.jsx"
import HeaderLinks from "components/Header/HeaderLinks.jsx"
import SubmenuLinks from "components/Header/SubmenuLinks.jsx"

import "assets/scss/material-kit-react.scss?v=1.7.0"

import layoutStyle from "assets/jss/material-kit-react/views/layout.jsx"
import { urlFor } from "utils/image-url"

/**
 * Main layout component
 *
 * The Layout component wraps around each page and template.
 * It also provides the header, footer as well as the main
 * styles, and meta data for each page.
 *
 */
const DefaultLayout = ({ ...props }) => {
    const { classes, data, children, bodyClass, isHome, mainImage } = props

    const siteSettings = data.allSanitySiteSettings.edges[0].node
    const siteMenuItems = data.allSanityMenu.edges

    function readMenuLink(node) {
        if (node.node.content.length === 1) {
            return node.node.content[0]._type == `post`
                ? `/` + node.node.content[0].slug.current
                : `/tag/` + node.node.content[0].slug.current
        }
        return null
    }

    function readMenuItems(node) {
        const link = readMenuLink(node)
        if (link) {
            return null
        }

        return node.node.content.map((node) => {
            return {
                description: node.title,
                link:
                    node._type === `post`
                        ? `/` + node.slug.current
                        : `/tag/` + node.slug.current,
            }
        })
    }

    const items = siteMenuItems.map((node, i) => {
        return {
            description: node.node.title,
            icon: node.node.icon,
            items: readMenuItems(node),
            link: readMenuLink(node),
        }
    })

    let subtitles = []

    if (typeof siteSettings.subtitle !== `undefined`) {
        subtitles = siteSettings.subtitle.split(`\n`)
    }

    return (
        <>
            <div className={classes.wrapper}>
                <Helmet>
                    <html lang={`en`} />
                    <body className={bodyClass} />
                </Helmet>
                <Header
                    brand="Home"
                    rightLinks={<SubmenuLinks items={items} />}
                    fixed
                    color="gryg"
                    changeColorOnScroll={{
                        height: 400,
                        color: `white`,
                    }}
                />
                <Parallax filter image={urlFor(mainImage).url()}>
                    <GridContainer style={{ margin: `0 auto` }}>
                        <GridItem>
                            <div className={classes.brand}>
                                <div
                                    className="row text-white text-center"
                                    style={{
                                        height: `100v`,
                                        padding: `200px 15px 0px 15px`,
                                    }}
                                >
                                    <div
                                        className="col-md-12"
                                        style={{
                                            height: `60vh`,
                                            display: `flex`,
                                            justifyContent: `center`,
                                            flexDirection: `column`,
                                        }}
                                    ><br/>
                                        <h1 className={classes.trajanWhite}>
                                            {siteSettings.title}
                                        </h1>
                                        <h3 className={classes.trajanWhite}>
                                            <i>
                                                {subtitles.length > 0
                                                    ? subtitles[0]
                                                    : ``}
                                            </i>
                                        </h3>
                                    </div>
                                    <div
                                        className="col-md-12"
                                        style={{ height: `40vh` }}
                                    >
                                        <h3 className={classes.trajanWhite}>
                                            {subtitles.length > 1
                                                ? subtitles[1]
                                                : ``}{` `}
                                        </h3>
                                        <h3 className={classes.trajanWhite}>
                                            {subtitles.length > 2
                                                ? subtitles[2]
                                                : ``}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </GridItem>
                    </GridContainer>
                </Parallax>

                <div className={classNames(classes.main)}>
                    <div className={classes.container}>{children}</div>
                </div>
                
            </div>
        </>
    )
}

DefaultLayout.propTypes = {
    children: PropTypes.node.isRequired,
    bodyClass: PropTypes.string,
    mainImage: PropTypes.object.isRequired,
    isHome: PropTypes.bool,
    data: PropTypes.shape({
        allSanityPost: PropTypes.object.isRequired,
        allSanitySiteSettings: PropTypes.object.isRequired,
    }).isRequired,
}

const DefaultLayoutSettingsQuery = props => (
    <StaticQuery
        query={graphql`
            query SanitySettings {
                allSanityPost(
                    filter: {
                        categories: { elemMatch: { title: { eq: "About" } } }
                    }
                ) {
                    edges {
                        node {
                            slug {
                                current
                            }
                            title
                        }
                    }
                }

                allSanitySiteSettings {
                    edges {
                        node {
                            id
                            title
                            subtitle
                            description
                        }
                    }
                }

                allSanityMenu(sort: { fields: publishOrder }) {
                    edges {
                        node {
                            id
                            title
                            publishOrder
                            content {
                                ... on SanityCategory {
                                    id
                                    slug {
                                        current
                                    }
                                    _type
                                    title
                                }
                                ... on SanityPost {
                                    id
                                    slug {
                                        current
                                    }
                                    _type
                                    title
                                }
                            }
                            icon
                        }
                    }
                }
            }
        `}
        render={data => <DefaultLayout data={data} {...props} />}
    />
)

export default withStyles(layoutStyle)(DefaultLayoutSettingsQuery)
