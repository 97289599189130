/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "gatsby";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, CloudDownload, Description } from "@material-ui/icons";

// React icons
import { FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function RenderSubMenu({ ...props }) {
    const { classes, items } = props;

    const menuOptions = items.map(item => {
        const display = item.link ? (
            <CustomDropdown
                noLiPadding
                buttonText="Components"
                buttonProps={{
                    className: classes.navLink,
                    color: "transparent"
                }}
                buttonIcon={Apps}
                dropdownList={[
                    <Link to="/" className={classes.dropdownLink}>
                        All components
                    </Link>,
                    <a
                        href="/"
                        target="_blank"
                        className={classes.dropdownLink}
                    >
                        Documentation
                    </a>
                ]}
            />
        ) : (
            <Button
                href="/"
                color="transparent"
                target="_blank"
                className={classes.navLink}
            >
                <Description className={classes.icons} />
                {item.description}
            </Button>
        );
        return <ListItem className={classes.listItem}>{display}</ListItem>;
    });
}

function HeaderLinks({ ...props }) {
    const { classes, items } = props;

    return (
        <List className={classes.list}>
            <RenderSubMenu classes="classes" items="items"></RenderSubMenu>
            <ListItem className={classes.listItem}>
                <CustomDropdown
                    noLiPadding
                    buttonText="Components"
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={Apps}
                    dropdownList={[
                        <Link to="/" className={classes.dropdownLink}>
                            All components
                        </Link>,
                        <a
                            href="https://creativetimofficial.github.io/material-kit-react/#/documentation"
                            target="_blank"
                            className={classes.dropdownLink}
                        >
                            Documentation
                        </a>
                    ]}
                />
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button
                    href="https://www.creative-tim.com/product/material-kit-react"
                    color="transparent"
                    target="_blank"
                    className={classes.navLink}
                >
                    <CloudDownload className={classes.icons} /> Download
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Tooltip
                    id="instagram-twitter"
                    title="Follow us on twitter"
                    placement={
                        typeof window !== "undefined" && window.innerWidth > 959
                            ? "top"
                            : "left"
                    }
                    classes={{ tooltip: classes.tooltip }}
                >
                    <Button
                        href="https://twitter.com/CreativeTim"
                        target="_blank"
                        color="transparent"
                        className={classes.navLink}
                    >
                        <FaTwitter />
                    </Button>
                </Tooltip>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Tooltip
                    id="instagram-facebook"
                    title="Follow us on facebook"
                    placement={
                        typeof window !== "undefined" && window.innerWidth > 959
                            ? "top"
                            : "left"
                    }
                    classes={{ tooltip: classes.tooltip }}
                >
                    <Button
                        color="transparent"
                        href="https://www.facebook.com/CreativeTim"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <FaFacebook />
                    </Button>
                </Tooltip>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Tooltip
                    id="instagram-tooltip"
                    title="Follow us on instagram"
                    placement={
                        typeof window !== "undefined" && window.innerWidth > 959
                            ? "top"
                            : "left"
                    }
                    classes={{ tooltip: classes.tooltip }}
                >
                    <Button
                        color="transparent"
                        href="https://www.instagram.com/CreativeTimOfficial"
                        target="_blank"
                        className={classes.navLink}
                    >
                        <FaInstagram />
                    </Button>
                </Tooltip>
            </ListItem>
        </List>
    );
}

export default withStyles(headerLinksStyle)(HeaderLinks);
