/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { container } from "assets/jss/material-kit-react.jsx";
import backimage from "assets/img/cross.png";

const layoutStyle = {
    container,
    brand: {
        color: "#FFFFFF",
        textAlign: "center"
    },
    title: {
        fontSize: "4.2rem",
        fontWeight: "600",
        display: "inline-block",
        position: "relative"
    },
    subtitle: {
        fontSize: "1.313rem",
        maxWidth: "500px",
        margin: "10px 0 0"
    },
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
        /*         "&::before": {
            content: `""`,
            height: "100%",
            width: "100%",
            position: "absolute",
            transform: "rotate(10deg)",
            background: `url(${backimage}) center center repeat`,
            backgroundSize: "15% auto",
            opacity: ".7",
            animationName: "fadeIn",
            animationDuration: "3s",
            animationFillMode: "forwards"
        } */
    },
    mainRaised: {
        margin: "-180px 30px 0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    link: {
        textDecoration: "none"
    },
    textCenter: {
        textAlign: "center"
    },
    trajanWhite: {
        fontFamily: "trajan-color",
        fontFeatureSettings: '"ss20"'
    }
};

export default layoutStyle;
