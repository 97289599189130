/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
// nodejs library that concatenates classes
import classNames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
import { Link } from "gatsby"
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import Hidden from "@material-ui/core/Hidden"
import Drawer from "@material-ui/core/Drawer"
// @material-ui/icons
import Menu from "@material-ui/icons/Menu"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

// core components
import headerStyle from "assets/jss/material-kit-react/components/headerStyle.jsx"

class Header extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            mobileOpen: false,
        }
    }
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    };
    headerColorChange = () => {
        const { classes, color, changeColorOnScroll } = this.props
        const windowsScrollTop = window.pageYOffset
        
        // if (windowsScrollTop > changeColorOnScroll.height) {
        //     document.body
        //         .getElementsByTagName("header")[0]
        //         .classList.remove(classes[color]);
        //     document.body
        //         .getElementsByTagName("header")[0]
        //         .classList.add(classes[changeColorOnScroll.color]);
        // } else {
        //     document.body
        //         .getElementsByTagName("header")[0]
        //         .classList.add(classes[color]);
        //     document.body
        //         .getElementsByTagName("header")[0]
        //         .classList.remove(classes[changeColorOnScroll.color]);
        // }
    };
    componentDidMount() {
        if (this.props.changeColorOnScroll) {
            window.addEventListener(`scroll`, this.headerColorChange)
        }
    }
    componentWillUnmount() {
        if (this.props.changeColorOnScroll) {
            window.removeEventListener(`scroll`, this.headerColorChange)
        }
    }
    render() {
        const {
            classes,
            color,
            rightLinks,
            leftLinks,
            brand,
            fixed,
            absolute,
        } = this.props
        const appBarClasses = classNames({
            [classes.appBar]: true,
            [classes[color]]: color,
            [classes.absolute]: absolute,
            [classes.fixed]: fixed,
        })
        const brandComponent = ( 
            <Link className={classes.title} to="/">
                {brand}
            </Link>
        )
        return (
            <AppBar className={appBarClasses}>
                <Toolbar className={classes.container}>
                    {leftLinks !== undefined ? brandComponent : null}
                    <div className={classes.flex}>
                        {leftLinks !== undefined ? (
                            <Hidden smDown implementation="css">
                                {leftLinks}
                            </Hidden>
                        ) : (
                            brandComponent
                        )}
                    </div>
                    <Hidden smDown implementation="css">
                        {rightLinks}
                    </Hidden>
                    {/*
                    <div>                     
                        <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top" className={classes.form}>
                            <input type="hidden" name="cmd" value="_donations" />
                            <input type="hidden" name="business" value="stseraphimgiving@gmail.com" />
                            <input type="hidden" name="item_name" value="Donate to Saint Seraphim Orthodox Cathedral" />
                            <input type="hidden" name="currency_code" value="USD" />
                            <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
                            <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                        </form>
                    </div>
                    */}
                    <div>
                        {/* <a target="_blank" href="https://stseraphim.givingfire.com/">Giving</a> */}
                        <a target="_blank" className={classes.title} href="https://stseraphim.givingfire.com/">
                            Giving
                        </a>
                    </div>
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={this.handleDrawerToggle}
                        >
                            <Menu />
                        </IconButton>
                    </Hidden>                    
                </Toolbar>
                <Hidden mdUp implementation="js">
                    <Drawer
                        variant="temporary"
                        anchor={`right`}
                        open={this.state.mobileOpen}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        onClose={this.handleDrawerToggle}
                    >
                        <div className={classes.appResponsive}>
                            {leftLinks}
                            {rightLinks}
                        </div>
                    </Drawer>
                </Hidden>
            </AppBar>
        )
    }
}

Header.defaultProp = {
    color: `gryg`,
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    color: PropTypes.oneOf([
        `primary`,
        `info`,
        `success`,
        `warning`,
        `danger`,
        `transparent`,
        `white`,
        `rose`,
        `dark`,
        `gryg`,
    ]),
    rightLinks: PropTypes.node,
    leftLinks: PropTypes.node,
    brand: PropTypes.string,
    fixed: PropTypes.bool,
    absolute: PropTypes.bool,
    // this will cause the sidebar to change the color from
    // this.props.color (see above) to changeColorOnScroll.color
    // when the window.pageYOffset is heigher or equal to
    // changeColorOnScroll.height and then when it is smaller than
    // changeColorOnScroll.height change it back to
    // this.props.color (see above)
    changeColorOnScroll: PropTypes.shape({
        height: PropTypes.number.isRequired,
        color: PropTypes.oneOf([
            `primary`,
            `info`,
            `success`,
            `warning`,
            `danger`,
            `transparent`,
            `white`,
            `rose`,
            `dark`,
            `gryg`,
        ]).isRequired,
    }),
}

export default withStyles(headerStyle)(Header)
