module.exports = {
    clientConfig: {
        apiHost: "https://cdn.sanity.io",
        projectId: process.env.GATSBY_SANITY_PROJECT_ID || "boyvw97y",
        dataset: process.env.GATSBY_SANITY_DATASET || "test",
        token:
            process.env.SANITY_READ_TOKEN ||
            "sksJ9plxriG6l1g5FsAGD0XYFNlk4HSRJDvNln2Rq7x6RWFgEnaDecXYMRmlS36ldDcFbRO2pbXqF6E1EoRxLb4hp2aToaUYCwTuKiIr7gR0RIhpRw0MlZ1Z5ODzsexEuTZoiCv7H20wqruoA0ZMpR8QnGiher1qf2Mkd1govL4quukCX5Uc"
    }
};
