/*!

=========================================================
* Material Kit React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {
    container,
    defaultFont,
    primaryColor,
    infoColor,
    successColor,
    warningColor,
    dangerColor,
    roseColor,
    transition,
    boxShadow,
    drawerWidth,
    grygColor,
} from "assets/jss/material-kit-react.jsx"

const headerStyle = {
    appBar: {
        display: `flex`,
        border: `0`,
        padding: `0.625rem 0`,
        marginBottom: `20px`,
        color: `#555`,
        width: `100%`,
        backgroundColor: `#fff`,
        boxShadow:
            `0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)`,
        transition: `all 150ms ease 0s`,
        alignItems: `center`,
        flexFlow: `row nowrap`,
        justifyContent: `flex-start`,
        position: `relative`,
        zIndex: `unset`,
    },
    form: {
        marginBottom: `0`,
        marginRight: `2em`,
        marginLeft: `1em`,
        lineHeight: `0.5`,
        flex: `1`,
        alignItems: `center`,
    },
    absolute: {
        position: `absolute`,
        zIndex: `1100`,
    },
    fixed: {
        position: `fixed`,
        zIndex: `1100`,
    },
    container: {
        ...container,
        minHeight: `50px`,
        flex: `1`,
        alignItems: `center`,
        justifyContent: `space-between`,
        display: `flex`,
        flexWrap: `nowrap`,
    },
    flex: {
        flex: `1`,
        alignItems: `center`,
    },
    title: {
        ...defaultFont,
        lineHeight: `30px`,
        fontSize: `18px`,
        borderRadius: `3px`,
        textTransform: `none`,
        color: `inherit`,
        padding: `8px 16px`,
        letterSpacing: `unset`,
        "&:hover,&:focus": {
            color: `inherit`,
            background: `transparent`,
        },
    },
    appResponsive: {
        margin: `20px 10px`,
    },
    primary: {
        backgroundColor: primaryColor,
        color: `#FFFFFF`,
        boxShadow:
            `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(156, 39, 176, 0.46)`,
    },
    info: {
        backgroundColor: infoColor,
        color: `#FFFFFF`,
        boxShadow:
            `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(0, 188, 212, 0.46)`,
    },
    success: {
        backgroundColor: successColor,
        color: `#FFFFFF`,
        boxShadow:
            `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(76, 175, 80, 0.46)`,
    },
    warning: {
        backgroundColor: warningColor,
        color: `#FFFFFF`,
        boxShadow:
            `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(255, 152, 0, 0.46)`,
    },
    danger: {
        backgroundColor: dangerColor,
        color: `#FFFFFF`,
        boxShadow:
            `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)`,
    },
    rose: {
        backgroundColor: roseColor,
        color: `#FFFFFF`,
        boxShadow:
            `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)`,
    },
    gryg: {
        backgroundColor: grygColor,
        color: `#FFFFFF`,
        boxShadow:
            `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(233, 30, 99, 0.46)`,
    },
    transparent: {
        backgroundColor: `transparent !important`,
        boxShadow: `none`,
        paddingTop: `25px`,
        color: `#FFFFFF`,
    },
    dark: {
        color: `#FFFFFF`,
        backgroundColor: `#212121 !important`,
        boxShadow:
            `0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(33, 33, 33, 0.46)`,
    },
    white: {
        border: `0`,
        padding: `0.625rem 0`,
        marginBottom: `20px`,
        color: `#555`,
        backgroundColor: `#fff !important`,
        boxShadow:
            `0 4px 18px 0px rgba(0, 0, 0, 0.12), 0 7px 10px -5px rgba(0, 0, 0, 0.15)`,
    },
    drawerPaper: {
        border: `none`,
        bottom: `0`,
        transitionProperty: `top, bottom, width`,
        transitionDuration: `.2s, .2s, .35s`,
        transitionTimingFunction: `linear, linear, ease`,
        width: drawerWidth,
        ...boxShadow,
        position: `fixed`,
        display: `block`,
        top: `0`,
        height: `100vh`,
        right: `0`,
        left: `auto`,
        visibility: `visible`,
        overflowY: `visible`,
        borderTop: `none`,
        textAlign: `left`,
        paddingRight: `0px`,
        paddingLeft: `0`,
        ...transition,
    },
    list: {
        ...defaultFont,
        fontSize: `14px`,
        margin: 0,
        paddingLeft: `0`,
        listStyle: `none`,
        paddingTop: `0`,
        paddingBottom: `0`,
        color: `rose`,
    },
    listItem: {
        float: `left`,
        color: `inherit`,
        position: `relative`,
        display: `block`,
        width: `auto`,
        margin: `0`,
        padding: `0`,
    },
    listItemText: {
        padding: `0 !important`,
    },
}

export default headerStyle
