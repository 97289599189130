import sanity from "../../sanity-config";

import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(sanity);

export function urlFor(source) {
    return builder.image(source);
}

export function urlForFile(source) {
    var refParts = source.split("-");
    return sanity.clientConfig.apiHost
        .concat("/files/", sanity.clientConfig.projectId, "/")
        .concat(sanity.clientConfig.dataset, "/")
        .concat(refParts[1], ".", refParts[2]);
}