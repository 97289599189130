/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "gatsby";
import { navigate } from "gatsby";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import {
    Info,
    Apps,
    Schedule,
    CloudDownload,
    Description,
    Camera,
    Directions,
    DateRange,
    LocalLibrary
} from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx";
import Button from "components/CustomButtons/Button.jsx";

import headerLinksStyle from "assets/jss/material-kit-react/components/headerLinksStyle.jsx";

function SubmenuLinks({ ...props }) {
    const { classes, items } = props;
    const menuIcons = {
        info: Info,
        apps: Apps,
        schedule: Schedule,
        cloudDownload: CloudDownload,
        description: Description,
        camera: Camera,
        directions: Directions,
        dateRange: DateRange,
        localLibrary: LocalLibrary
    };

    const menuOptions = items.map((item, i) => {
        const MenuIcon =
            item.icon === null || item.icon === "undefined"
                ? Info
                : menuIcons[item.icon];
        const display =
            item.link !== null ? (
                <Link
                    to={item.link}
                    color="transparent"
                    target="_blank"
                    className={classes.navLink}
                >
                    <MenuIcon className={classes.buttonIcon} />
                    {item.description}
                </Link>
            ) : (
                <CustomDropdown
                    noLiPadding
                    buttonText={item.description}
                    buttonProps={{
                        className: classes.navLink,
                        color: "transparent"
                    }}
                    buttonIcon={MenuIcon}
                    dropdownList={item.items.map(option => (
                        <Link to={option.link} className={classes.dropdownLink}>
                            {option.description}
                        </Link>
                    ))}
                />
            );

        return (
            <ListItem key={i} className={classes.listItem}>
                {display}
            </ListItem>
        );
    });

    return <List className={classes.list}>{menuOptions}</List>;
}

export default withStyles(headerLinksStyle)(SubmenuLinks);
